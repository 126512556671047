// npm
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// root
import { pageContextProps } from '../../gatsby-node'

// components
import ArtworkLandingLayout from '@templates/layouts/ArtworkLandingLayout'

interface Props {
  data: Queries.ArtworkCategoryLandingQuery
  pageContext: PageProps & pageContextProps
}

const ArtworkCategoryLanding = ({ data, pageContext }: Props) => {
  return (
    <ArtworkLandingLayout
      artworks={data.allDatoCmsArtwork.edges}
      categoryData={data.datoCmsArtworkCategory}
      pageContext={pageContext}
    />
  )
}

export const query = graphql`
  query ArtworkCategoryLanding(
    $skip: Int!
    $perPage: Int!
    $categoryPageId: String!
  ) {
    datoCmsArtworkCategory(originalId: { eq: $categoryPageId }) {
      artworkText
      name
    }
    allDatoCmsArtwork(
      limit: $perPage
      skip: $skip
      sort: {
        fields: [availability___position, meta___createdAt]
        order: [ASC, DESC]
      }
      filter: { category: { originalId: { eq: $categoryPageId } } }
    ) {
      edges {
        node {
          ...artworkCard
        }
      }
    }
  }
`

export default ArtworkCategoryLanding
